import { configureStore } from "@reduxjs/toolkit";
import currentUserReducer from "./features/currentUser";
import currentShopReducer from "./features/currentShop";

export const store = configureStore({
  reducer: {
    currentUser: currentUserReducer,
    currentShop: currentShopReducer,
  },
});
