import { createTheme } from "@mui/material";

export const theme = createTheme({
  customShadows: {
    other: "0px 2px 8px rgb(20 20 20 / 15%)",
  },
  palette: {
    primary: {
      main: "#aa0000",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#c58d7c",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#f29b18",
    },
    error: {
      main: "#EF4444",
    },
    info: {
      main: "#1195c1",
    }, //85de73ff
    success: {
      main: "#28c111",
      contrastText: "#FFFFFF",
    },
    text: {
      disabled: "#BDBDBD",
      primary: "#5b0000",
    },
  },
});
