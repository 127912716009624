import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import PageLoader from "./components/common/PageLoader";
import sapi from "./sajax";
import { setCurrentUser } from "./features/currentUser";
import { setCurrentShop } from "./features/currentShop";

const Login = lazy(() => import("./screens/Login"));
const ShopSelectionLayout = lazy(() => import("./screens/ShopSelectionLayout"));
const Home = lazy(() => import("./screens/Home"));
const PostAuthLayout = lazy(() => import("./screens/PostAuthLayout"));
const BrandMasterist = lazy(() => import("./screens/masters/BrandList"));
const UnitMasterist = lazy(() => import("./screens/masters/UnitList"));
const CategoryMasterist = lazy(() => import("./screens/masters/CategoryList"));
const ItemNameMasterist = lazy(() => import("./screens/masters/ItemNameList"));
const ItemMasterList = lazy(() => import("./screens/masters/ItemMasterList"));
const ItemMaster = lazy(() => import("./screens/masters/ItemMasterForm"));
const ItemStock = lazy(() => import("./screens/masters/ItemStockForm"));
const ItemStockList = lazy(() => import("./screens/masters/ItemStockList"));
const OrderList = lazy(() => import("./screens/transactions/OrderList"));
const OrderDet = lazy(() => import("./screens/transactions/OrderDet"));
const BankList = lazy(() => import("./screens/masters/BankList"));
const ProcessingOrder = lazy(() => import("./screens/orders/ProcessingOrder"));
const Order = lazy(() => import("./screens/transactions/Order"));
const CustomerMaster = lazy(() => import("./screens/masters/CustomerForm"));
const CustomerList = lazy(() => import("./screens/masters/CustomerList"));
const CustomerPriceList = lazy(() => import("./screens/masters/CustomerPriceList"));
const CustomerPrice = lazy(() => import("./screens/masters/CustomerPrice"));
const WorkDesk = lazy(() => import("./screens/orders/WorkDesk"));

const router = createBrowserRouter([
  {
    path: "/stores",
    element: (
      <Suspense fallback={<PageLoader />}>
        <ShopSelectionLayout />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: (
          <Guard>
            <Suspense fallback={<PageLoader />}>
              <PostAuthLayout />
            </Suspense>
          </Guard>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/orders/processing-order" replace />,
          },
          {
            path: "home",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Home />
              </Suspense>
            ),
          },
          {
            path: "orders/processing-order",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ProcessingOrder />
              </Suspense>
            ),
          },
          {
            path: "orders/workdesk",
            element: (
              <Suspense fallback={<PageLoader />}>
                <WorkDesk />
              </Suspense>
            ),
          },
          {
            path: "masters/brand",
            element: (
              <Suspense fallback={<PageLoader />}>
                <BrandMasterist />
              </Suspense>
            ),
          },
          {
            path: "masters/unit",
            element: (
              <Suspense fallback={<PageLoader />}>
                <UnitMasterist />
              </Suspense>
            ),
          },
          {
            path: "masters/category",
            element: (
              <Suspense fallback={<PageLoader />}>
                <CategoryMasterist />
              </Suspense>
            ),
          },
          {
            path: "masters/itemname",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ItemNameMasterist />
              </Suspense>
            ),
          },
          {
            path: "masters/itemmasterlist",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ItemMasterList />
              </Suspense>
            ),
          },
          {
            path: "masters/itemmaster",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ItemMaster />
              </Suspense>
            ),
          },
          {
            path: "masters/itemmaster/:hash",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ItemMaster />
              </Suspense>
            ),
          },
          {
            path: "masters/itemstock",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ItemStock />
              </Suspense>
            ),
          },
          {
            path: "masters/itemstock/:masterhash",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ItemStock />
              </Suspense>
            ),
          },
          {
            path: "masters/itemstock/edit/:hash",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ItemStock />
              </Suspense>
            ),
          },
          {
            path: "masters/itemstocklist",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ItemStockList />
              </Suspense>
            ),
          },
          {
            path: "masters/bank",
            element: (
              <Suspense fallback={<PageLoader />}>
                <BankList />
              </Suspense>
            ),
          },
          {
            path: "masters/customerlist",
            element: (
              <Suspense fallback={<PageLoader />}>
                <CustomerList />
              </Suspense>
            ),
          },
          {
            path: "masters/customer",
            element: (
              <Suspense fallback={<PageLoader />}>
                <CustomerMaster />
              </Suspense>
            ),
          },
          {
            path: "masters/customer/:hash",
            element: (
              <Suspense fallback={<PageLoader />}>
                <CustomerMaster />
              </Suspense>
            ),
          },
          {
            path: "masters/customerpricelist",
            element: (
              <Suspense fallback={<PageLoader />}>
                <CustomerPriceList />
              </Suspense>
            ),
          },
          {
            path: "masters/customerprice/:hash",
            element: (
              <Suspense fallback={<PageLoader />}>
                <CustomerPrice />
              </Suspense>
            ),
          },
          {
            path: "masters/customerprice",
            element: (
              <Suspense fallback={<PageLoader />}>
                <CustomerPrice />
              </Suspense>
            ),
          },

          {
            path: "transactions/orderlist",
            element: (
              <Suspense fallback={<PageLoader />}>
                <OrderList />
              </Suspense>
            ),
          },
          {
            path: "transactions/orderdet/:hash",
            element: (
              <Suspense fallback={<PageLoader />}>
                <OrderDet />
              </Suspense>
            ),
          },
          {
            path: "transactions/order",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Order />
              </Suspense>
            ),
          },
          {
            path: "transactions/order/:hash",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Order />
              </Suspense>
            ),
          },
          {
            path: "dashboard",
            element: (
              <Suspense fallback={<PageLoader />}>
                <ProcessingOrder />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "login",
        element: (
          <Suspense fallback={<PageLoader />}>
            <Login />
          </Suspense>
        ),
      },
    ],
  },
]);

function Guard({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  useEffect(() => {
    sapi.getApi().hasAuth((res) => {
      if (res.mm !== undefined && res.mm !== null && res.mm === true) {
        //window.location.href = "/under-maintenance";
      } else {
        if (res.s) {
          dispatch(setCurrentUser(res.d.current_user));
          dispatch(setCurrentShop(res.d.shop));
          localStorage.setItem("shopid", res.d.shop["shopid"]);
          setIsLoggedIn(true);
        } else if (!res.s && (res.f === 1006 || res.f === "1006")) {
          dispatch(setCurrentUser(res.d.current_user));
          setIsLoggedIn(true);
          localStorage.setItem("shopid", null);
          //navigation("/stores");
        } else {
          setIsLoggedIn(false);
        }
      }
    });
  }, [dispatch, navigation]);

  return isLoggedIn === null ? (
    <PageLoader />
  ) : isLoggedIn === true ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" replace />
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
