import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };

export const currentShopSlice = createSlice({
  name: "currentShop",
  initialState,
  reducers: {
    setCurrentShop: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrentShop } = currentShopSlice.actions;
export default currentShopSlice.reducer;
